import Vue from 'vue'
import axios from 'axios'
import firebaseConfig from '../../firebaseConfig'

const axiosAPI = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/api/v1/` : `http://localhost:5001/${firebaseConfig.projectId}/us-central1/api/v1/`,
  timeout: 10000
})

// token이 계속 바뀌므로, 인터셉터를 만들어야 된다.
axiosAPI.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.Authorization = localStorage.getItem('stdt')
  // console.log('axios.request', config.headers.Authorization)
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

axiosAPI.interceptors.response.use(function (response) {
  const token = response.data.token
  // console.log('axios.response', token)
  if (token) localStorage.setItem('stdt', token)
  return response
}, function (error) {
  // console.log(error.response)
  // switch (error.response.status) {
  //   case 400:
  //     store.commit('pop', { msg: `잘못된 요청입니다(${error.response.status}:${error.message})`, color: 'error' })
  //     break
  //   case 401:
  //     store.commit('delToken')
  //     store.commit('pop', { msg: `인증 오류입니다(${error.response.data.msg}:${error.message})`, color: 'error' })
  //     break
  //   case 403:
  //     store.commit('pop', { msg: `이용 권한이 없습니다(${error.response.data.msg}:${error.message})`, color: 'warning' })
  //     break
  //   default:
  //     store.commit('pop', { msg: `알수 없는 오류입니다(${error.response.data.msg}:${error.message})`, color: 'error' })
  //     break
  // }
  return Promise.reject(error)
})

Vue.prototype.$axios = axiosAPI
