import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '학원명을 입력하세요',
    user: null,
    token: '',
    totStickerCnt: 30,
    firebaseLoaded: false,
    userLv: 100,
    stdUser: {
      user: JSON.parse(localStorage.getItem('stduser')),
      token: localStorage.getItem('stdt'),
      lv: 500
    },
    sb: {
      act: false,
      msg: '',
      color: 'error'
    }
  },
  mutations: {
    setTitle (state, payload) {
      state.title = payload
    },
    setUser (state, user) {
      state.user = user
    },
    setToken (state, token) {
      state.token = token
    },
    setTotStickerCnt (state, totStickerCnt) {
      state.totStickerCnt = totStickerCnt
    },
    setFirebaseLoaded (state) {
      state.firebaseLoaded = true
    },
    setUserLv (state, lv) {
      state.userLv = lv
    },
    setStdUser (state, user) {
      state.stdUser.user = user
      state.stdUser.token = localStorage.getItem('stdt')
    },
    pop (state, d) {
      state.sb.msg = d.msg
      state.sb.color = d.color
      state.sb.act = false
      if (d.act === undefined) state.sb.act = true
    }
  },
  actions: {
    // 보통 비동기를 처리할 때, 사용한다
    getUser ({ commit }, user) {
      commit('setUser', user)
      if (!user) return
      user.getIdToken()
        .then(token => {
          // console.log(token)
          commit('setToken', token)
          commit('setFirebaseLoaded')
        })
    },
    getTitle ({ commit }, title) {
      if (!title) return
      commit('setTitle', title)
    },
    getTotStickerCnt ({ commit }, cnt) {
      if (!cnt) return
      commit('setTotStickerCnt', cnt)
    },
    getUserLv ({ commit }, lv) {
      if (lv < 0) return
      commit('setUserLv', lv)
    }
  },
  modules: {
  }
})
