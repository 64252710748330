<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="blue-grey darken-4"
      dark
      persistent
      fixed
    >
      <site-menu :items="site.menu"></site-menu>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      flat
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="$store.state.user"
      ></v-app-bar-nav-icon>
      <site-title :title="$store.state.title"></site-title>

      <v-btn text dark x-large @click="goLibrary" style="margin-left: 100px">Library</v-btn>
      <v-btn text dark x-large @click="goStdMng">학생관리</v-btn>
      <v-spacer/>
      <v-btn v-if="$store.state.userLv == 0" @click="goAdmin">관리자 모드</v-btn>
      <v-btn icon @click="handleFullScreen()">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <!-- <v-menu offset-y origin="center center" class="elelvation-1" :nudge-bottom="14" transition="scale-transition">
        <v-btn icon flat slot="activator">
          <v-badge color="red" overlap>
            <span slot="badge">3</span>
            <v-icon medium>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <notification-list></notification-list>
      </v-menu> -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              size="36px"
            >
              <img
                v-if="$store.state.user && $store.state.user.photoURL"
                alt="Avatar"
                :src="$store.state.user.photoURL"
              >
              <v-icon
                v-else
              >mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card v-if="$store.state.user" max-width="300px">
          <v-list>
            <v-list-item class="d-flex justify-center">
              <v-list-item-avatar size="64" class="mx-0">
                <v-avatar size="64" v-if="$store.state.user.photoURL">
                  <img
                    alt="Avatar"
                    :src="$store.state.user.photoURL"
                  >
                </v-avatar>
                <v-avatar size="64" v-else>
                  <v-icon size="64">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">{{ $store.state.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item @click="updateProfile">
              <v-list-item-icon><v-icon>mdi-account-edit</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>정보수정</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그아웃</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card v-else max-width="300">
          <v-list>
            <v-list-item @click="signIn">
              <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그인</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <site-footer></site-footer>

  </v-app>
</template>

<script>
import SiteTitle from '@/components/AppToolbar'
import SiteFooter from '@/components/AppFooter'
import SiteMenu from '@/components/AppDrawer'
import Utils from '@/utils'

export default {
  components: { SiteTitle, SiteFooter, SiteMenu },
  name: 'DefaultLayout',
  data () {
    return {
      drawer: false,
      site: {
        menu: {
          mypage: [
            {
              title: '내 프로필',
              icon: 'mdi-card-account-details',
              to: '/main/profile',
              active: true
            }
          ],
          home: [
            {
              title: '화이트보드',
              icon: 'mdi-view-dashboard',
              to: '/main/home',
              active: true
            }
          ],
          academy: [
            {
              title: 'Configuration',
              subItems: [
                {
                  title: '학원정보',
                  icon: 'mdi-school',
                  to: '/manage/academy'
                },
                {
                  title: '학생관리',
                  icon: 'mdi-account-multiple-plus',
                  to: '/manage/students'
                },
                // {
                //   title: '스티커',
                //   icon: 'mdi-stamper',
                //   to: '/manage/point'
                // },
                {
                  title: '즐겨찾기',
                  icon: 'mdi-bookmark-check',
                  to: '/manage/links'
                }
              ]
            }
          ],
          repo: [
            {
              title: 'Repository',
              subItems: [
                {
                  title: 'LIBRARY',
                  icon: 'mdi-library',
                  to: '/repo/library'
                }
                // {
                //   title: 'QUEST',
                //   icon: 'mdi-comment-question',
                //   to: '/repo/quest'
                // }
              ]
            }
          ],
          class: [
            {
              title: '클래스룸',
              subItems: [
                {
                  title: '세트',
                  icon: 'mdi-cards-variant',
                  to: '/class/sets'
                },
                {
                  title: '클래스',
                  icon: 'mdi-account-group',
                  to: '/class/rooms'
                }
              ]
            }
          ],
          games: [
            {
              title: '게임',
              subItems: [
                {
                  title: 'Test',
                  icon: 'mdi-gamepad',
                  to: '/games/1'
                }
              ]
            }
          ],
          boards: [
            {
              title: '커뮤니티',
              subItems: [
                {
                  title: '추천글',
                  icon: 'mdi-thumb-up',
                  to: '/boards/goods'
                },
                {
                  title: '자유로운 글',
                  icon: 'mdi-coffee',
                  to: '/boards/1'
                },
                {
                  title: '매매/나눔',
                  icon: 'mdi-swap-horizontal-bold',
                  to: '/boards/1'
                },
                {
                  title: '구인구직',
                  icon: 'mdi-human-greeting',
                  to: '/boards/1'
                }
              ]
            }
          ]
        }
      }
    }
  },
  created () {
    // this.subscribe()
  },
  mounted () {
    //
  },
  methods: {
    updateProfile () {
      this.$router.push('/main/profile')
    },
    signIn () {
      this.$router.push('/auth/signin')
    },
    async signOut () {
      if (this.$firebase.auth().currentUser) {
        await this.$firebase.auth().signOut()
      }
      this.$router.push('/auth/signin')
    },
    handleFullScreen () {
      Utils.toggleFullScreen()
    },
    goAdmin () {
      this.$router.push('/admin/academy')
    },
    goLibrary () {
      this.$router.push('/repo/library')
    },
    goStdMng () {
      this.$router.push('/manage/students')
    }
  }
}
</script>
