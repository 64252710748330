import Vue from 'vue'
import VueRouter from 'vue-router'
// import firebase from '../plugins/firebase'
import store from '../store'
// import { publicRoute, protectedRoute } from './config'
// const routes = publicRoute.concat(protectedRoute)
import { AuthLayout, DefaultLayout, PublicLayout, StudentLayout, AdminLayout } from '@/components/layouts'

Vue.use(VueRouter)

// const pageLogWrite = (to) => {
//   const { uid, email } = store.state.user
//   Vue.prototype.$firebase.firestore().collection('pageLogs').add({
//     uid,
//     email,
//     to: to.path,
//     createdAt: new Date()
//   }).catch((e) => console.log(e.message))
// }

const levelCheck = (level) => {
  return (to, from, next) => {
    const user = Vue.prototype.$firebase.auth().currentUser
    // console.log('levelCheck', store.state.userLv)
    if (!user) {
      return next('/auth/signin')
    }

    if (store.state.userLv > level) {
      return next('/auth/signin')
    }

    // else {
    //   const msg = [
    //     '관리자 이상만 들어갈 수 있습니다',
    //     '사용자 이상만 들어갈 수 있습니다',
    //     '손님 이상만 들어갈 수 있습니다'
    //   ]
    //   if (store.state.claims.level > level) throw Error(msg[level])
    //   pageLogWrite(to)
    // }
    next()
  }
}

const studentLevelCheck = (level) => {
  return (to, from, next) => {
    const user = store.state.stdUser.user
    // console.log('student user', user)
    // console.log('student levelCheck', store.state.stdUser.lv)
    if (!user) {
      return next('/auth/signin')
    }

    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'pubhome',
    component: PublicLayout,
    redirect: '/home',
    children: [
      {
        path: '',
        component: () => import('@/views/public/Home.vue')
      },
      {
        path: '/terms',
        component: () => import('@/views/public/Terms.vue')
      }
    ]
  },
  {
    path: '/test',
    component: () => import('@/views/test/AudioListPlay')
  },
  // {
  //   path: '/test',
  //   component: () => import('@/views/test/Axios')
  // },
  // {
  //   path: '/test2',
  //   component: () => import('@/views/test/HorizentalX')
  // },
  // {
  //   path: '/test3',
  //   component: () => import('@/views/test/Test')
  // },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { title: 'Signin' },
    redirect: '/auth/signin',
    hidden: true,
    children: [
      {
        path: 'signin',
        name: 'Signin',
        meta: { title: 'Signin', requiresAuth: false },
        props: { step: 1 },
        component: () => import('@/views/auth/SignOnlyGo.vue')
      }
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { title: 'Signup' },
    redirect: '/auth/signup',
    hidden: true,
    children: [
      {
        path: 'signup',
        name: 'signup',
        meta: { title: 'Signup', requiresAuth: false },
        props: { step: 2 },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/SignOnlyGo.vue')
      }
    ]
  },
  {
    path: '/main',
    component: DefaultLayout,
    redirect: '/main/profile',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/mypage/Profile.vue')
      },
      {
        path: 'whiteboard',
        component: () => import('@/views/WhiteBoard.vue')
      }
    ],
    beforeEnter: levelCheck(100)
  },
  // 학원정보
  {
    path: '/manage',
    component: DefaultLayout,
    children: [
      {
        name: 'checkTerms',
        path: 'checkTerms',
        component: () => import('@/views/manage/CheckTerms')
      },
      {
        name: 'academy',
        path: 'academy',
        component: () => import('@/views/manage/Academy')
      },
      {
        name: 'students',
        path: 'students',
        component: () => import('@/views/manage/Students')
      },
      {
        name: 'studentAdd',
        path: 'student/add',
        props: true,
        component: () => import('@/views/manage/StudentAdd')
      },
      {
        name: 'point',
        path: 'point',
        props: true,
        component: () => import('@/views/manage/Point')
      },
      {
        name: 'links',
        path: 'links',
        component: () => import('@/views/manage/Links')
      }
    ],
    beforeEnter: levelCheck(100)
  },
  // repo
  {
    path: '/repo',
    component: DefaultLayout,
    children: [
      {
        name: 'library',
        path: 'library',
        component: () => import('@/views/repo/Library')
      },
      {
        name: 'libraryAdd',
        path: 'library/add',
        component: () => import('@/views/repo/ItemAdd')
      },
      {
        name: 'libraryEdit',
        path: 'library/edit',
        props: true,
        component: () => import('@/views/repo/ItemEdit')
      }
      // {
      //   name: 'quest',
      //   path: 'quest',
      //   component: () => import('@/views/repo/Quest')
      // }
    ],
    beforeEnter: levelCheck(100)
  },
  // students
  {
    path: '/std',
    component: StudentLayout,
    children: [
      {
        name: 'stdMyPage',
        path: 'profile',
        component: () => import('@/views/student/Profile')
      },
      {
        name: 'stdLibrary',
        path: 'library',
        component: () => import('@/views/student/Library')
      }
    ],
    beforeEnter: studentLevelCheck(500)
  },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        name: 'adminConfig',
        path: 'config',
        component: () => import('@/views/admin/CommonDataAdd')
      },
      {
        name: 'adminAcademy',
        path: 'academy',
        component: () => import('@/views/admin/Academy')
      },
      {
        name: 'adminLibrary',
        path: 'library',
        props: true,
        component: () => import('@/views/admin/Library')
      },
      {
        name: 'adminFolders',
        path: 'folders',
        props: true,
        component: () => import('@/views/admin/Folders')
      }
    ],
    beforeEnter: levelCheck(0)
  },
  {
    path: '/404',
    name: '404',
    meta: { title: 'Not Found', requiresAuth: false },
    component: () => import(/* webpackChunkName: "errors-404" */ '@/views/error/NotFound.vue')
  },
  {
    path: '/500',
    name: '500',
    meta: { title: 'Server Error', requiresAuth: false },
    component: () => import(/* webpackChunkName: "errors-500" */ '@/views/error/Error.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "errors-404" */ '@/views/error/NotFound.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

const waitFirebase = () => {
  return new Promise((resolve, reject) => {
    let cnt = 0
    const tmr = setInterval(() => {
      if (store.state.firebaseLoaded) {
        clearInterval(tmr)
        resolve()
      } else if (cnt++ > 500) {
        reject(Error('제한 시간 초과, 인터넷 연결을 확인하세요'))
      }
    }, 10)
  })
}

router.beforeEach((to, from, next) => {
  Vue.prototype.$Progress.start()

  // 모든 사용자 접근
  if (to.path === '/' || to.path === '/terms' || to.path === '/auth/signin') {
    return next()
  }

  // 학생 사용자 접근
  if (to.path === '/std/profile' || to.path === '/std/library') {
    return next()
  }

  waitFirebase()
    .then(() => {
      const user = Vue.prototype.$firebase.auth().currentUser
      store.dispatch('getUser', user)
      next()
    }).catch(e => {
      next('/auth/signin')
      Vue.prototype.$toast.error(e.message)
    })
})

router.afterEach((to, from) => {
  Vue.prototype.$Progress.finish()
})

export default router
