<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="blue-grey darken-4"
      dark
      persistent
      fixed
    >
      <site-menu :items="site.menu"></site-menu>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      flat
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="$store.state.user"
      ></v-app-bar-nav-icon>
      <site-title :title="$store.state.title"></site-title>

      <v-spacer/>
      <v-btn v-if="$store.state.userLv == 0" @click="goUser">일반 모드</v-btn>
      <!-- <v-menu offset-y origin="center center" class="elelvation-1" :nudge-bottom="14" transition="scale-transition">
        <v-btn icon flat slot="activator">
          <v-badge color="red" overlap>
            <span slot="badge">3</span>
            <v-icon medium>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
        <notification-list></notification-list>
      </v-menu> -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              size="36px"
            >
              <img
                v-if="$store.state.user && $store.state.user.photoURL"
                alt="Avatar"
                :src="$store.state.user.photoURL"
              >
              <v-icon
                v-else
              >mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card v-if="$store.state.user" max-width="300px">
          <v-list>
            <v-list-item class="d-flex justify-center">
              <v-list-item-avatar size="64" class="mx-0">
                <v-avatar size="64" v-if="$store.state.user.photoURL">
                  <img
                    alt="Avatar"
                    :src="$store.state.user.photoURL"
                  >
                </v-avatar>
                <v-avatar size="64" v-else>
                  <v-icon size="64">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">{{ $store.state.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item @click="updateProfile">
              <v-list-item-icon><v-icon>mdi-account-edit</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>정보수정</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그아웃</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card v-else max-width="300">
          <v-list>
            <v-list-item @click="signIn">
              <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그인</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <site-footer></site-footer>

    <v-snackbar
      v-model="$store.state.sb.act"
      :color="$store.state.sb.color"
      timeout="5000"
    >
      {{ $store.state.sb.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="$store.commit('pop', { act: false })"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import SiteTitle from '@/components/admin/AppToolbar'
import SiteFooter from '@/components/admin/AppFooter'
import SiteMenu from '@/components/admin/AppDrawer'

export default {
  components: { SiteTitle, SiteFooter, SiteMenu },
  name: 'AdminLayout',
  data () {
    return {
      drawer: false,
      site: {
        menu: {
          mypage: [
            {
              title: '내 프로필',
              icon: 'mdi-card-account-details',
              to: '/main/profile',
              active: true
            }
          ],
          academy: [
            {
              title: 'Configuration',
              subItems: [
                {
                  title: '학원정보',
                  icon: 'mdi-school',
                  to: '/admin/academy'
                },
                {
                  title: '공통코드',
                  icon: 'mdi-xml',
                  to: '/admin/config'
                }
                // {
                //   title: '스티커',
                //   icon: 'mdi-stamper',
                //   to: '/manage/point'
                // },
                // {
                //   title: '즐겨찾기',
                //   icon: 'mdi-bookmark-check',
                //   to: '/manage/links'
                // }
              ]
            }
          ],
          repo: [
            {
              title: 'Repository',
              subItems: [
                {
                  title: 'LIBRARY',
                  icon: 'mdi-library',
                  to: '/admin/library'
                }
              ]
            }
          ],
          folders: [
            {
              title: '폴더 수정',
              subItems: [
                {
                  title: '폴더ID 수정',
                  icon: 'mdi-briefcase-edit',
                  to: '/admin/folders'
                }
              ]
            }
          ],
          class: [
            {
              title: '클래스룸',
              subItems: [
                {
                  title: '세트',
                  icon: 'mdi-cards-variant',
                  to: '/class/sets'
                },
                {
                  title: '클래스',
                  icon: 'mdi-account-group',
                  to: '/class/rooms'
                }
              ]
            }
          ],
          games: [
            {
              title: '게임',
              subItems: [
                {
                  title: 'Test',
                  icon: 'mdi-gamepad',
                  to: '/games/1'
                }
              ]
            }
          ]
        }
      }
    }
  },
  created () {
    // this.subscribe()
  },
  mounted () {
    //
  },
  methods: {
    updateProfile () {
      this.$router.push('/main/profile')
    },
    signIn () {
      this.$router.push('/auth/signin')
    },
    async signOut () {
      if (this.$firebase.auth().currentUser) {
        await this.$firebase.auth().signOut()
      }
      this.$router.push('/auth/signin')
    },
    goUser () {
      this.$router.push('/manage/academy')
    }
  }
}
</script>
