<template>
  <div>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center">{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
          <v-list-item-subtitle class="text-center">{{ $store.state.user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.academy" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.repo" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.folders" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
export default {
  props: ['items'],
  data () {
    return {
      //
    }
  }
}
</script>
