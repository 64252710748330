<template>
  <v-footer
    app
    color="primary"
    dark
    inset
  >

    <div>&copy; {{ new Date().getFullYear() + ' '}}<span class="logo-cap-class">클래스</span><span class="logo-cap-i">아이</span></div>
    <!-- <v-spacer/>
    <v-btn text @click="goTerms">이용약관</v-btn> -->
  </v-footer>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  methods: {
    // goTerms () {
    //   window.open('/terms', 'Popup')
    // }
  }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:wght@300;400;600;700;800&display=swap');

.logo-cap-class {
  font-family: 'Open Sans', sans-serif;
  text-align : center;
}
.logo-cap-i {
  font-family: 'Open Sans', sans-serif;
  text-align : center;
  color: yellow;
}
</style>
