<template>
  <v-toolbar-title>
    {{ title }}
  </v-toolbar-title>
</template>
<script>
export default {
  props: ['title'],
  data () {
    return {
      //
    }
  }
}
</script>
