import Vue from 'vue'
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import VuetifyToast from 'vuetify-toast-snackbar'

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon
  }
})

const VuetifyObj = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1C75BC'
        // primary: '#4187F9'
        // secondary: '#b0bec5',
        // accent: '#8c9eff',
        // error: '#b71c1c'
      }
    }
  }
})

Vue.use(VuetifyToast, {
  x: 'center', // default
  y: 'bottom', // default
  color: 'info', // default
  icon: '',
  iconColor: '', // default
  classes: [
    'body-2'
  ],
  timeout: 5000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: false, // default
  showClose: false, // default
  closeText: '닫기', // default
  closeIcon: 'close', // default
  closeColor: '', // default
  slot: [], // default
  shorts: {
    custom: {
      color: 'purple'
    }
  },
  property: '$toast', // default
  $vuetify: VuetifyObj.framework
})

export default VuetifyObj
