import cryptoPp from '../../cryptoPassphrase'
var CryptoJS = require('crypto-js')

const toggleFullScreen = () => {
  const doc = window.document
  const docEl = doc.documentElement

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

const ENC = (msg) => {
  const ret = CryptoJS.AES.encrypt(msg, cryptoPp.key).toString()
  // console.log('ENC', ret)
  return ret
}

const DEC = (pw) => {
  const bytes = CryptoJS.AES.decrypt(pw, cryptoPp.key)
  const ret = bytes.toString(CryptoJS.enc.Utf8)
  // console.log('DEC', ret)
  return ret
}

export default {
  toggleFullScreen, ENC, DEC
}
