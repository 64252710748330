export default {
  apiKey: 'AIzaSyD1xBdatShxxUPF3_xqFsaoBLuu9TGcVYo',
  authDomain: 'classi-lab.firebaseapp.com',
  databaseURL: 'https://classi-lab.firebaseio.com',
  projectId: 'classi-lab',
  storageBucket: 'classi-lab.appspot.com',
  messagingSenderId: '717032647693',
  appId: '1:717032647693:web:05dc1fbb1a1a594d27b944',
  measurementId: 'G-G2J46BS918'
}
