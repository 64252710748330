<template>
  <div>
    <v-list>

      <v-list-item>
        <v-list-item-action>
          <v-avatar size="64" v-if="user.photoURL">
            <img
              alt="Avatar"
              :src="user.photoURL"
            >
          </v-avatar>
          <v-avatar size="64" v-else>
            <v-icon size="64">mdi-account</v-icon>
          </v-avatar>

        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ user.name ? user.name : '미등록' }}</v-list-item-title>
          <v-list-item-subtitle >{{ user.userid }}</v-list-item-subtitle>
          <!-- <v-list-item-subtitle>
            <v-icon
              dense
              color="red darken-2"
              dark
            >mdi-stamper</v-icon>
            {{ user.stickerCnt }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-icon
              dense
              color="teal darken-2"
              dark
            >mdi-file-powerpoint-box</v-icon>
            {{ numberWithComma }}
          </v-list-item-subtitle> -->

        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item class="d-flex justify-center">
        <v-list-item-avatar size="64" class="mx-0">
          <v-avatar size="64" v-if="user.photoURL">
            <img
              alt="Avatar"
              :src="user.photoURL"
            >
          </v-avatar>
          <v-avatar size="64" v-else>
            <v-icon size="64">mdi-account</v-icon>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center">{{ user.name ? user.name : '미등록' }}</v-list-item-title>
          <v-list-item-subtitle class="text-center">{{ user.userid }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

    </v-list>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item to="/std/profile" exact>
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>내 프로필</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.repo" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>

          <!-- <v-list-item-icon if="subItem.extra">
            <v-icon @click="addGroup">mdi-plus</v-icon>
          </v-list-item-icon> -->
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
  </div>
</template>
<script>
export default {
  props: ['items'],
  data () {
    return {
    }
  },
  computed: {
    user () {
      let user = this.$store.state.stdUser.user
      if (!user) user = localStorage.getItem('stduser')
      return user
    },
    numberWithComma () {
      let user = this.$store.state.stdUser.user
      if (!user) user = localStorage.getItem('stduser')

      if (!user.pointScore) return 0
      const x = user.pointScore
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
