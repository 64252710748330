import Vue from 'vue'
import firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/firebase-database'
import 'firebase/firestore'
import 'firebase/storage'
import firebaseConfig from '../../firebaseConfig'
import store from '../store'
// import router from '../router'

firebase.initializeApp(firebaseConfig)

Vue.prototype.$firebase = firebase
Vue.prototype.$isFirebaseAuth = false

firebase.auth().onAuthStateChanged(async (user) => {
  Vue.prototype.$isFirebaseAuth = true
  if (user) {
    // console.log('Log In')
    // User is signed in.
    if (localStorage) {
      localStorage.acid = user.uid
    }

    try {
      await firebase.firestore().collection('academies').doc(user.uid).get()
        .then((r) => {
          if (r.exists) {
            const { name, stickerCnt, stickerReward } = r.data()
            store.dispatch('getTitle', name)
            store.dispatch('getTotStickerCnt', stickerCnt)
            if (localStorage) {
              localStorage.title = name
              localStorage.stickerTot = stickerCnt
              localStorage.stickerReward = stickerReward
            }
          }
        })
    } catch (e) {
      console.log('signIn 1', e.message)
    }

    try {
      await firebase.firestore().collection('users').doc(user.uid).get()
        .then((r) => {
          if (r.exists) {
            // console.log(r.data())
            store.dispatch('getUserLv', r.data().level)

            firebase.firestore().collection('users').doc(user.uid).update({
              displayName: user.displayName,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              visitedAt: new Date(),
              visitCount: firebase.firestore.FieldValue.increment(1)
            })
          }
        })
    } catch (e) {
      console.log('signIn 2', e.message)
    }
  } else {
    // console.log('Log Out')
    // No user is signed in.
    delete localStorage.acid
    delete localStorage.title
    delete localStorage.stickerTot
    delete localStorage.stickerReward
  }
  store.dispatch('getUser', user)
})
