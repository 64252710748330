<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      class="blue-grey darken-4"
      dark
      persistent
      fixed
    >
      <site-menu
        :items="site.menu"
      ></site-menu>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      flat
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="user"
      ></v-app-bar-nav-icon>
      <site-title :title="user.academyDesc"></site-title>

      <v-spacer/>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              size="36px"
            >
              <img
                v-if="user && user.photoURL"
                alt="Avatar"
                :src="user.photoURL"
              >
              <v-icon
                v-else
              >mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card v-if="user" width="250px">
          <v-list>
            <v-list-item class="d-flex justify-center">
              <v-list-item-avatar size="32" class="mx-0">
                <v-avatar size="32" v-if="user.photoURL">
                  <img
                    alt="Avatar"
                    :src="user.photoURL"
                  >
                </v-avatar>
                <v-avatar size="32" v-else>
                  <v-icon size="32">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">{{ user.name ? user.name : '미등록' }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">{{ user.userid }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item @click="updateProfile">
              <v-list-item-icon><v-icon>mdi-account-edit</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>정보수정</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그아웃</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card v-else max-width="400">
          <v-list>
            <v-list-item @click="signIn">
              <v-list-item-icon><v-icon>mdi-login</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그인</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <site-footer></site-footer>
    <v-snackbar
      v-model="$store.state.sb.act"
      :color="$store.state.sb.color"
      timeout="5000"
    >
      {{ $store.state.sb.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="$store.commit('pop', { act: false })"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import SiteTitle from '@/components/students/AppToolbar'
import SiteFooter from '@/components/students/AppFooter'
import SiteMenu from '@/components/students/AppDrawer'

export default {
  components: { SiteTitle, SiteFooter, SiteMenu },
  name: 'StudentLayout',
  data () {
    return {
      drawer: false,
      site: {
        menu: {
          mypage: [
            {
              title: '내 프로필',
              icon: 'mdi-card-account-details',
              to: '/std/profile',
              active: true
            }
          ],
          repo: [
            {
              title: '학습공간',
              subItems: [
                {
                  title: '도서관',
                  icon: 'mdi-library',
                  to: '/std/library'
                }
              ]
            }
          ],
          games: [
            {
              title: '게임',
              subItems: [
                {
                  title: 'Test',
                  icon: 'mdi-gamepad',
                  to: '/games/1'
                }
              ]
            }
          ]
        }
      }
    }
  },
  computed: {
    user () {
      let user = this.$store.state.stdUser.user
      if (!user) {
        user = localStorage.getItem('stduser')
      }
      return user
    }
  },
  methods: {
    updateProfile () {
      this.$router.push('/std/profile')
    },
    signIn () {
      this.$router.push('/auth/signin')
    },
    async signOut () {
      delete localStorage.stduser
      delete localStorage.stdt

      this.$router.push('/auth/signin')
    }
  }
}
</script>
