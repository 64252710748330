<template>
  <v-app>
    <v-app-bar
      color="primary"
      elevate-on-scroll
      dark
      app
    >
      <v-toolbar-title>
        <div style="width:60px;">
          <span class="logo-cap-class">Class</span><span class="logo-cap-i"> i</span>
        </div>
      </v-toolbar-title>
      <v-spacer/>
      <v-menu offset-y v-if="$store.state.user">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-avatar
              size="36px"
            >
              <img
                v-if="$store.state.user && $store.state.user.photoURL"
                alt="Avatar"
                :src="$store.state.user.photoURL"
              >
              <v-icon
                v-else
              >mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card v-if="$store.state.user" max-width="300px">
          <v-list>
            <v-list-item class="d-flex justify-center">
              <v-list-item-avatar size="64" class="mx-0">
                <v-avatar size="64" v-if="$store.state.user.photoURL">
                  <img
                    alt="Avatar"
                    :src="$store.state.user.photoURL"
                  >
                </v-avatar>
                <v-avatar size="64" v-else>
                  <v-icon size="64">mdi-account</v-icon>
                </v-avatar>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">{{ $store.state.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list-item @click="updateProfile">
              <v-list-item-icon><v-icon>mdi-account-edit</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>정보수정</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item @click="signOut">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-content><v-list-item-title>로그아웃</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn text v-else @click="signIn">로그인</v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer app color="primary" dark >
      <div>&copy; {{ new Date().getFullYear() + ' '}}<span class="logo-cap-class">Class</span><span class="logo-cap-i"> i</span> Lab.</div>
      <v-spacer/>
      <v-btn text @click="goTerms">이용약관</v-btn>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: 'PublicLayout',
  data () {
    return {
      //
    }
  },
  methods: {
    updateProfile () {
      this.$router.push('/main/profile')
    },
    signIn () {
      this.$router.push('/auth/signin')
    },
    signOut () {
      if (this.$firebase.auth().currentUser) {
        this.$firebase.auth().signOut()
      }
      this.$router.push('/auth/signin')
    },
    goTerms () {
      window.open('/terms', 'Popup')
    }
  }

}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Open+Sans:wght@300;400;600;700;800&display=swap');

.logo-cap-class {
  font-family: 'Open Sans', sans-serif;
  text-align : center;
}
.logo-cap-i {
  font-family: 'Lobster', cursive;
  text-align : center;
  color: yellow ;
}
</style>
