<template>
  <div>
    <v-list>
      <!-- <v-list-item class="d-flex justify-center">
        <v-list-item-avatar size="64" class="mx-0">
          <v-avatar size="64" v-if="$store.state.user.photoURL">
            <img
              alt="Avatar"
              :src="$store.state.user.photoURL"
            >
          </v-avatar>
          <v-avatar size="64" v-else>
            <v-icon size="64">mdi-account</v-icon>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item> -->

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center">{{ $store.state.user.displayName ? $store.state.user.displayName : '미등록' }}</v-list-item-title>
          <v-list-item-subtitle class="text-center">{{ $store.state.user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
    <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item to="/main/profile" exact>
          <v-list-item-icon>
            <v-icon>mdi-card-account-details</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>내 프로필</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <!-- <v-list nav dense>
      <v-list-item-group color="primary">
        <v-list-item to="/main/whiteboard" exact>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>화이트보드</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider> -->

    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.academy" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.repo" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>

          <!-- <v-list-item-icon if="subItem.extra">
            <v-icon @click="addGroup">mdi-plus</v-icon>
          </v-list-item-icon> -->
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <!-- <v-list nav dense>
      <v-list-item-group color="primary" v-for="(item, i) in items.class" :key="i" v-model="item.active">
        <v-subheader>{{ item.title }}</v-subheader>

        <v-list-item
          v-for="(subItem, j) in item.subItems"
          :key="j"
          :to="subItem.to"
        >
          <v-list-item-icon>
            <v-icon>{{ subItem.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
  </div>
</template>
<script>
export default {
  props: ['items'],
  data () {
    return {
      //
    }
  }
}
</script>
